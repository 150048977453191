<template>
    <div>
        <Breadcrumbs main="İlgi Durumu" title="İlgi Durumu Düzenle" :links="breadLinks"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">

                    <div class="card">
                        <div class="card-header">
                            <h5>İlgi Durumu Düzenle</h5><span>Girdiğiniz bilgiler ile markanıza <span class="text-danger">bağlı olan</span> ilgi durumunu güncelleyebilirsiniz.</span>
                        </div>
                        <div class="card-body">
                        
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(updateForm)">
                                    <div class="form-row">
                                        <div class="col-md-4 mb-3">
                                            <validation-provider
                                            name="Tanım"
                                            :rules="{ required: true, min:3}"
                                            v-slot="validationContext"
                                            >
                                                <b-form-group id="tanimGroup" label="Tanım" label-for="tanim" description="İlgi durumunun tanımını yazınız.">
                                                    <b-form-input
                                                    id="tanim"
                                                    name="tanim"
                                                    v-model="Form.tanim"
                                                    size="sm"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="tanim-live-feedback"
                                                    ></b-form-input>
        
                                                    <b-form-invalid-feedback id="tanim-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <validation-provider
                                            name="Oran"
                                            :rules="{ required: true,numeric: true}"
                                            v-slot="validationContext"
                                            >
                                                <b-form-group id="oranGroup" label="Oran" label-for="oran" description="İlgi durumunun ne kadar yüksek olduğunu sayı cinsinden belirtiniz.">
                                                    <b-form-input
                                                    id="renk"
                                                    name="renk"
                                                    v-model="Form.oran"
                                                    size="sm"
                                                    type="number"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="renk-live-feedback"
                                                    ></b-form-input>
        
                                                    <b-form-invalid-feedback id="renk-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <b-form-group id="renkGroup" label="Renk" label-for="renk" description="İlgi durumunun rengini seçiniz.">
                                                <chrome-picker  v-model="Form.renk" @input="updateColor"></chrome-picker>
                                        </b-form-group>
                                    </div>
                                    <b-button type="submit" variant="primary">Kaydet</b-button>
                                    <b-button class="ml-2" variant="danger" @click="resetForm()">Formu Temizle</b-button>
                                </b-form>
                            </validation-observer>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>

import { Chrome } from 'vue-color';

import { mapState } from 'vuex';

export default {
    components: {
        'chrome-picker': Chrome,
    },
    data() {
        return {
            breadLinks:[
                {path:'/ilgi_durumlari/listele', title:'İlgi durumlari listesi', icon:'list'}
            ],
            Form : {
                encId:'',
                renk: '',
                oran: 0,
                tanim: ''
            },
        }
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    mounted() {
        this.getIlgiDurum();
    },
    methods: {
        updateColor(color) {
            console.log(color.hex);
            this.Form.renk = color.hex;
        },
        getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
        },
        resetForm(){
            this.markaAddForm = {
                encMarkaId: "",
                renk: "",
                oran: 0,
                tanim: ''
            };

            this.$nextTick(() => {
            this.$refs.observer.reset();
            });            
        },
        updateForm(){
            var self = this;
            this.$connection.postData('ilgidurum/duzelt',self.Form,
                function(response){
                    console.log('update data:',response.data);
                    self.$alertHelper.basic_with_action("Başarılı","İlgi Durum Güncellendi",function(){
                        console.log('encId:',response.data.encId);
                        //self.$router.push({name:'onbasvuru-update',params:{ id:response.data.encId}})
                    },undefined
                    );
                },undefined
            );
        },
        getIlgiDurum(){
            var self = this;
            this.$connection.getData(`ilgidurum/get?id=${this.$route.params.id}`,
                function(response){
                    console.log(response.data);
                    self.Form.encId = response.data.encId;
                    self.Form.renk = response.data.renk;
                    self.Form.oran = response.data.oran;
                    self.Form.tanim = response.data.tanim;

                },
                function(error){
                console.log("error çalıştı");
                }
            );
        },
    },
}
</script>
<style>
    
</style>