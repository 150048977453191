<template>
    <div>
        <Breadcrumbs main="Marka" title="Kullanıcı Markaları" :links="breadLinks"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">

              <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                      <h5>Kullanıcı Markaları</h5><span>Giriş yapmış olduğunuz hesabın marka ve alt markaları aşağıda listelenmiştir.</span>
                    </div>

                    <div class="card-body">
                      <c-table :tablefields="tablefields" :items="itemList">
                        <template #cell(actions)="row">
                          <b-btn-group>
                            <router-link :to="'/marka/detail/' + row.item.encId">
                              <b-button size="sm" class="mr-1" variant="primary">Detay</b-button>
                            </router-link>
                            <router-link :to="'/marka/update/' + row.item.encId">
                              <b-button size="sm" class="mr-1" variant="secondary">Düzenle</b-button>
                            </router-link>
                            <b-button size="sm"  variant="danger" @click="deleteMarka(row.item.encId)">
                              Sil
                            </b-button>
                          </b-btn-group>
                        </template>
                        <template #cell(aktif)="data">
                            <aktif-cell :isAktif="data.value"></aktif-cell>
                        </template>
                        <template #cell(kayitTarihi)="row">
                            <tarih-cell :kayitTarihi="row.item.kayitTarihi" :guncelTarih="row.item.guncelTarih" :rowKey="row.item.encId" ></tarih-cell>
                        </template>
                      </c-table>
                    </div>

                </div>
              </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>


export default {

  data(){
    return{
      tablefields: [
        { key: 'actions', label: 'İŞLEMLER', sortable: true, },
        { key: 'adi', label: 'ADI', sortable: true, },
        { key: 'firmaAdi', label: 'FİRMA ADI', sortable: true, },
        { key: 'bayiAdedi', label: 'BAYİ ADEDİ', sortable: true },
        { key: 'aktif', label: 'AKTİF', sortable: true, },
        { key: 'kayitTarihi', label: 'KAYIT TARİHİ', sortable: true},
        { key: 'sira', label: 'SIRA', sortable: true},
      ],
      itemList : [],
      breadLinks:[
        { path:'/marka/ekle', title:'Alt marka ekle', icon:'plus'}
      ]
    }
  },
  computed: {
  },
  mounted() {
    // Set the initial number of items
    this.getMarkaList();
  },
  methods: {
    getMarkaList(){
        var self = this;
        this.$connection.getData('marka/alt-markalar-kullanici',
            function(response){
              self.itemList = response.data;
            },
            function(error){
            console.log("error çalıştı");
            });
    },
    deleteMarka(encId){
      var self = this;
      self.$alertHelper.advanced_with_action('MARKA SİL','Markayı kalıcı olarak silmek istediğinize emin misiniz ? \n (Bu işlem yetkiniz yok ise gerçekleşmeyecektir.)',
        function(){
          self.$connection.getData('marka/sil?eId='+encId,function(){
            self.itemList = self.itemList.filter(item => item.encId !=encId);
            self.$alertHelper.basic_with_title('Marka Sil','Marka Silindi');
          },undefined);
        },undefined,'warning');
    }
  }
}
</script>