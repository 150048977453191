import Vue from "vue"
import router from "../../router/index"
import axiosHelper from '../../helpers/axiosHelper'

const state = {
    user : {}
}

const getters = {
    isAuthenticated(state){
        return JSON.stringify(state.user) !== '{}'
    },
    getUser(state){
        return state.user
    }
}

const mutations = {
    setUser(state, payload){
        state.user = payload
    },
    clearUser(state){
        state.user = {}
    }
}

const actions = {
    initAuth(context){
        let user = localStorage.getItem("user")
         if(user){
             var userData = JSON.parse(user);
             let expirationDate = localStorage.getItem("expirationDate")
             let time = new Date().getTime()

             if(time >= +expirationDate){
                 console.log("token süresi geçmiş...")
                 context.dispatch("logout")
             } else {
                 let timerSecond = +expirationDate - time
                 context.dispatch("setTimeoutTimer", timerSecond)
             }
         } else {
             return false
         }
    },
    login(context, payload){
        console.log('payload:',payload);

        axiosHelper.postData('auth/login',payload.authData,
        function(response){
                context.commit("setUser", response.data)
                localStorage.setItem("user", JSON.stringify(response.data))
                
                localStorage.setItem("expirationDate", new Date().getTime() + +response.data.tokenExpiredMS)
    
                context.dispatch("setTimeoutTimer", +response.data.tokenExpiredMS)
                router.push("/");

            },function(error){
                console.log({error});
                });
    },
    logout(context){
        context.commit("clearUser")
        localStorage.removeItem("user")
        localStorage.removeItem("expirationDate")
        router.push("/auth/login")
    },
    setTimeoutTimer(context, expiresIn){
        setTimeout(() => {
            context.dispatch("logout")
        }, expiresIn)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}