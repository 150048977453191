<template>
    <div>
        <Breadcrumbs main="Aşama " title="Aşama Düzenle" :links="breadLinks"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">

                <div class="card">
                    <div class="card-header">
                        <h5>Aşama Düzenle</h5><span>Girdiğiniz bilgiler ile markanıza <span class="text-danger">bağlı olan</span> aşamayı düzenleyebilirsiniz.</span>
                    </div>
                    <div class="card-body">
                       
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(updateAsama)">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Adı"
                                        :rules="{ required: true, min: 3 }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="adiGroup" label="Adı" label-for="adi" description="Aşamanın adını giriniz.">
                                                <b-form-input
                                                id="adi"
                                                name="adi"
                                                v-model="Form.adi"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="adi-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="adi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Açıklama"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="aciklamaGroup" label="Açıklama" label-for="aciklama" description="Aşamanın açıklamasını yapınız.">
                                                <b-form-textarea
                                                id="aciklama"
                                                name="aciklama"
                                                v-model="Form.aciklama"
                                                rows="3"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="aciklama-live-feedback"
                                                ></b-form-textarea>
    
                                                <b-form-invalid-feedback id="aciklama-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Marka"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="encMarkaIdGroup" label="Marka" label-for="encMarkaId" description="Aşamanın markasını seçiniz.">
                                                <b-form-select
                                                id="encMarkaId"
                                                name="encMarkaId"
                                                v-model="Form.encMarkaId"
                                                :options="markalar"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="encMarkaId-live-feedback"
                                                ></b-form-select>
    
                                                <b-form-invalid-feedback id="encMarkaId-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Sira"
                                        :rules="{ required: true, numeric:3}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="siraGroup" label="Sıra" label-for="sira" description="Aşamanın sırasını yazınız.">
                                                <b-form-input
                                                id="sira"
                                                name="sira"
                                                v-model="Form.sira"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="sira-live-feedback"
                                                ></b-form-input>
    
                                                <b-form-invalid-feedback id="sira-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Aktif"
                                        :rules="{required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="aktifGroup" label-for="aktif" class="p-40" description="Aşamayı pasif olarak eklemek için kutudaki işareti kaldırabilirsiniz).">
                                                <b-form-checkbox
                                                id="aktif"
                                                name="aktif"
                                                v-model="Form.aktif"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="aktif-live-feedback"
                                                >Aktif</b-form-checkbox>
                                                <b-form-invalid-feedback id="aktif-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    
                                </div>
                                <b-button type="submit" variant="primary">Kaydet</b-button>
                                <b-button class="ml-2" variant="danger" @click="resetForm()">Formu Temizle</b-button>
                            </b-form>
                        </validation-observer>
                    </div>
                   
                </div>
            </div>
         </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            breadLinks:[
                {path:'/asama/listele', title:'Aşama listesi', icon:'list'}
            ],
            Form : {
                encId:'',
                adi: '',
                aciklama: '',
                encMarkaId: '',
                encAsamaAltId: '',
                ozellikler: '',
                aktif: true,
            },
            markalar: [
                { value: null, text: "Seçiniz" }
            ]
        };
    },
    props:{
        id : ''
    },
    mounted(){
        this.getAsama();
        this.getMarkaList();
        this.Form.encMarkaId = this.user.kullanici.markaId;
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
        },
        resetForm(){
            this.Form = {
                adi: '',
                aciklama: '',
                encMarkaId: '',
                encAsamaAltId: '',
                ozellikler: '',
                aktif: true,
            };

            this.$nextTick(() => {
            this.$refs.observer.reset();
            });            
        },
        getAsama(){
            var self = this;
            this.$connection.getData('asama/get?id='+this.$route.params.id,
                function(response){
                    self.Form.adi = response.data.adi;
                    self.Form.aciklama = response.data.aciklama;
                    self.Form.sira = response.data.sira;
                    self.Form.encMarkaId = response.data.markaId;
                    self.Form.encId = response.data.encId;
                },undefined
            );
        },
        updateAsama(){
            var self = this;
            this.$connection.postData('asama/duzenle',self.Form,
                function(response){
                    self.$alertHelper.basic_with_action("Başarılı","Aşama Düzenlendi",function(){
                        //self.$router.push({name:'onbasvuru-update',params:{ id:response.data.encId}})
                    },undefined
                    );
                },undefined
            );
        },
        getMarkaList(){
            var self = this;
            this.$connection.getData('marka/alt-markalar-kullanici',
                function(response){
                    self.markalar = response.data.map(function(obj) {
                        return {
                            value: obj.encId,   // Yeni isim özelliği
                            text: obj.adi      // Yeni yaş özelliği
                        };
                    }); 
                },
                function(error){
                console.log("error çalıştı");
                });
        }
    }
}
</script>