import Vue from "vue"
import router from "../../router/index"

const state = {
    showLoader : false 
}

const getters = {
   getLoaderStatus(state) {
        return state.showLoader;
   }
}

const mutations = {
   setLoaderStatus(context,payload){
        state.showLoader = payload;
   }
}

const actions = {
    setLoaderStatusAction(context,payload){
        context.commit('setLoaderStatus',payload);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}