<template>
    <div>
        <Breadcrumbs main="İlgi Durumu" title="İlgi Durumu Ekle" :links="breadLinks"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">

                    <div class="card">
                        <div class="card-header">
                            <h5>İlgi Durumu Ekle</h5><span>Girdiğiniz bilgiler ile markanıza <span class="text-danger">bağlı olan</span> başvuruları gruplamak için bir ilgi durumu ekleyebilirsiniz.</span>
                        </div>
                        <div class="card-body">
                        
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(addForm)">
                                    <div class="form-row">
                                        <div class="col-md-4 mb-3">
                                            <validation-provider
                                            name="Tanım"
                                            :rules="{ required: true, min:3}"
                                            v-slot="validationContext"
                                            >
                                                <b-form-group id="tanimGroup" label="Tanım" label-for="tanim" description="İlgi durumunun tanımını yazınız.">
                                                    <b-form-input
                                                    id="tanim"
                                                    name="tanim"
                                                    v-model="Form.tanim"
                                                    size="sm"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="tanim-live-feedback"
                                                    ></b-form-input>
        
                                                    <b-form-invalid-feedback id="tanim-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <validation-provider
                                            name="Oran"
                                            :rules="{ required: true,numeric: true}"
                                            v-slot="validationContext"
                                            >
                                                <b-form-group id="oranGroup" label="Oran" label-for="oran" description="İlgi durumunun ne kadar yüksek olduğunu sayı cinsinden belirtiniz.">
                                                    <b-form-input
                                                    id="renk"
                                                    name="renk"
                                                    v-model="Form.oran"
                                                    size="sm"
                                                    type="number"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="renk-live-feedback"
                                                    ></b-form-input>
        
                                                    <b-form-invalid-feedback id="renk-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <validation-provider
                                            name="Marka"
                                            :rules="{ required: true}"
                                            v-slot="validationContext"
                                            >
                                                <b-form-group id="encMarkaIdGroup" label="Marka" label-for="marka" description="İlgi durumunun ekleneceği markayı seçiniz.">
                                                    <b-form-select
                                                    id="encMarkaId"
                                                    name="encMarkaId"
                                                    v-model="Form.encMarkaId"
                                                    :options="markalar"
                                                    size="sm"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby="marka-live-feedback"
                                                    ></b-form-select>
        
                                                    <b-form-invalid-feedback id="marka-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <b-form-group id="renkGroup" label="Renk" label-for="renk" description="İlgi durumunun rengini seçiniz.">
                                                <chrome-picker :value="colors" @input="updateColor"></chrome-picker>
                                        </b-form-group>
                                    </div>
                                    <b-button type="submit" variant="primary">Kaydet</b-button>
                                    <b-button class="ml-2" variant="danger" @click="resetForm()">Formu Temizle</b-button>
                                </b-form>
                            </validation-observer>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
let defaultProps = {
  hex: '#194d33e6',
  hsl: {
    h: 150,
    s: 0.5,
    l: 0.2,
    a: 0.9
  },
  hsv: {
    h: 150,
    s: 0.66,
    v: 0.30,
    a: 0.9
  },
  rgba: {
    r: 159,
    g: 96,
    b: 43,
    a: 0.9
  },
  a: 0.9
}
import { Chrome } from 'vue-color';

import { mapState } from 'vuex';

export default {
    components: {
        'chrome-picker': Chrome,
    },
    data() {
        return {
            breadLinks:[
                {path:'/ilgi_durumlari/listele', title:'İlgi durumlari listesi', icon:'list'}
            ],
            Form : {
                encMarkaId: "",
                renk: "",
                oran: 0,
                tanim: ''
            },
            markalar: [
                { value: null, text: "Seçiniz" }
            ],
            colors:defaultProps,
            selectedColor: {
                hex: '#05301AE6', // Başlangıçta varsayılan renk
            },
        }
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    mounted() {
        this.getMarkaList();
        this.Form.encMarkaId = this.user.kullanici.markaId;
    },
    methods: {
        updateColor(color) {
            console.log(color.hex);
            this.Form.renk = color.hex;
        },
        getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
        },
        resetForm(){
            this.markaAddForm = {
                encMarkaId: "",
                renk: "",
                oran: 0,
                tanim: ''
            };

            this.$nextTick(() => {
            this.$refs.observer.reset();
            });            
        },
        addForm(){
            var self = this;
            this.$connection.postData('ilgidurum/ekle',self.Form,
                function(response){
                    console.log('update data:',response.data);
                    self.$alertHelper.basic_with_action("Başarılı","İlgi Durum Eklendi",function(){
                        console.log('encId:',response.data.encId);
                        //self.$router.push({name:'onbasvuru-update',params:{ id:response.data.encId}})
                    },undefined
                    );
                },undefined
            );
        },
        getMarkaList(){
            var self = this;
            this.$connection.getData('marka/alt-markalar-kullanici',
                function(response){
                    self.markalar = response.data.map(function(obj) {
                        return {
                            value: obj.encId,   // Yeni isim özelliği
                            text: obj.adi      // Yeni yaş özelliği
                        };
                    });
                },
                function(error){
                console.log("error çalıştı");
                }
            );
        },
    },
}
</script>
<style>
    
</style>