<template>
    <div>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-header" style="text-align: center;">
                        <h4>İLGİ DURUMU</h4>
                    </div>
                    <div class="ilgi-durum-row " >
                        <div class="card o-hidden" v-for="item in liste" @click="updateIlgiDurum(item)">
                            <div class=" b-r-4 card-body ilgi-item" 
                            :class="[item.selected ? '' : 'unselected']" 
                            :style="{'background-color': item.renk }"
                            >
                                <div class="media static-top-widget">
                                    <div class="align-self-center text-center"><feather type="target" class="middle"></feather></div>
                                    <div class="media-body">
                                    <h4 class="mb-0 counter">{{ item.tanim.toLocaleUpperCase() }}</h4><feather type="target" class="icon-bg"></feather>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card o-hidden" v-for="item in liste" @click="updateIlgiDurum(item)">
                            <div class=" b-r-4 card-body ilgi-item" 
                            :class="[item.selected ? '' : 'unselected']" 
                            :style="{'background-color': item.renk }"
                            >
                                <div class="media static-top-widget">
                                    <div class="align-self-center text-center"><feather type="target" class="middle"></feather></div>
                                    <div class="media-body">
                                    <h4 class="mb-0 counter">{{ item.tanim.toLocaleUpperCase() }}</h4><feather type="target" class="icon-bg"></feather>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            liste: [],
           
            basvuruIlgi: {},
            onBasvuruIlgi: {}
        }
    },
    props:{
        markaId: '',
        onBasvuruId: '',
        basvuruId: '',
    },
    computed:{
        ...mapState('auth', ['user']),
    },
    mounted() {
        this.getList();
        console.log(this.liste);
        this.getBasvuruIlgi();
        this.getOnBasvuruIlgi();
    },
    methods:{
        getList(){
            var self = this;
            this.$connection.getData('ilgidurum/get-list-by-marka?markaId='+self.user.kullanici.markaId,
                function(response){
                    self.liste = response.data.map((item)=>{
                        return { ...item,selected:false}
                    })
                    
                },
                function(error){
                console.log("error çalıştı");
                });
        },
        getBasvuruIlgi(){
            var self = this;
            console.log('once:',self.$props.basvuruId);
            if(self.$props.basvuruId != undefined){
                console.log(self.$props.basvuruId);
                self.$connection.getData('basvuru/get-ilgi-durumu?basvuruId='+self.$props.basvuruId,
                    function(response){
                        self.basvuruIlgi = response.data
                        const index = self.liste.findIndex(item => item.Id === self.basvuruIlgi.ilgiDurumId);
                        if (index !== undefined) {
                        index.selected = true;
                        }
                    },
                    function(error){
                    console.log("error çalıştı");
                    });
            }
        },
        getOnBasvuruIlgi(){
            var self = this;
            if(self.$props.onBasvuruId != undefined){
                self.$connection.getData('onbasvuru/get-ilgi-durumu?onbasvuruId='+self.$props.onBasvuruId,
                    function(response){
                        console.log('response data',response.data);
                        self.onBasvuruIlgi = response.data;
                        const index = self.liste.find(item => item.id === self.onBasvuruIlgi.ilgiDurumId);
                        if (index !== undefined) {
                        index.selected = true;
                        }
                    },
                    function(error){
                    console.log("error çalıştı");
                    });
            }
        },
        updateIlgiDurum(item){
            var self = this;
            if(this.$props.onBasvuruId != undefined){
                var body = {
                    ilgiDurumId : item.id,
                    onBasvuruId : this.$props.onBasvuruId
                }
    
                this.$connection.postData('onbasvuru/set-ilgi-durum',body,
                    function(response){
                        self.$alertHelper.basic_with_action("Başarılı","İlgi Durum Güncellendi",function(){
                            self.liste.map(item =>{
                                item.selected = false;
                            });
                            self.liste.find(item => item.id === body.ilgiDurumId).selected = true;
                        },undefined
                        );
                    },undefined
                );
            }
            else{
                var body = {
                    ilgiDurumId : item.id,
                    basvuruId : this.$props.basvuruId
                }
    
                this.$connection.postData('basvuru/set-ilgi-durum',body,
                    function(response){
                        self.$alertHelper.basic_with_action("Başarılı","İlgi Durum Güncellendi",function(){
                            self.liste.map(item =>{
                                item.selected = false;
                            });
                            self.liste.find(item => item.id === body.ilgiDurumId).selected = true;
                        },undefined
                        );
                    },undefined
                );
            }
        }
    },
}
</script>
<style>
    .unselected{
        opacity: 0.5;
    }
    .ilgi-durum-row{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        
    }
    .ilgi-item{
        cursor: pointer;
        transition: opacity 0.3s ease;
    }

    .ilgi-item:hover{
        opacity: 1;
    }
    .ilgi-item:hover ~ .ilgi-item {
        opacity: 0.5; /* Hover olan elemanın dışındaki diğer elemanlarını şeffaf yap */
    }
</style>