<template>
    <div>
        <Breadcrumbs main="Ön Başvuru " title="Ön Başvuru Ekle" :links="breadLinks"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">

                <div class="card">
                    <div class="card-header">
                        <h5>Ön Başvuru Ekle</h5><span>Girdiğiniz bilgiler ile markanıza <span class="text-danger">bağlı olan</span> bir ön başvuru ekleyebilirsiniz.</span>
                    </div>
                    <div class="card-body">
                       
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(addOnBasvuru)">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Ad Soyad"
                                        :rules="{ required: true, min: 3 }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="adSoyadGroup" label="Ad Soyad" label-for="adSoyad" description="Başvuruyu yapan kişinin adı ve soyadını giriniz.">
                                                <b-form-input
                                                id="adSoyad"
                                                name="adSoyad"
                                                v-model="Form.adSoyad"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="adSoyad-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="adSoyad-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Email"
                                        :rules="{ required: true, email:true, min: 3 }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="emailGroup" label="Email" label-for="email" description="Başvuruyu yapan kişinin email adresi.">
                                                <b-form-input
                                                id="email"
                                                name="email"
                                                type="email"
                                                v-model="Form.email"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="email-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="email-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="GSM" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="gsmGroup" label="GSM" label-for="gsm" >
                                                <b-form-input
                                                id="gsm"
                                                name="gsm"
                                                v-model="Form.gsm"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="gsm-live-feedback"
                                                v-mask="['0(5##) ### ####']"
                                                placeholder="0(5##) ### ####"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="gsm-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Şehir"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="sehirGroup" label="Şehir" label-for="sehir" description="Başvuru yapanın bulunduğu şehri seçiniz.">
                                                <b-form-select
                                                id="sehir"
                                                name="sehir"
                                                v-model="Form.sehirId"
                                                size="sm"
                                                :options="sehirler"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="sehir-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="sehir-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="İlgilendiği Şehir"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="ilgSehirIdGroup" label="İlgilendiği Şehir" label-for="ilgSehirId" description="Başvuruyu yapan kişinin ilgilendiğin şehiri seçiniz.">
                                                <b-form-select
                                                id="ilgSehirId"
                                                name="ilgSehirId"
                                                v-model="Form.ilgSehirId"
                                                size="sm"
                                                :options="sehirler"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="ilgSehirId-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="ilgSehirId-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="İlgilendiği İlçe"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="ilgIlceIdGroup" label="İlgilendiği İlçe" label-for="ilgIlceId" description="Başvuruyu yapan kişinin ilgilendiğin ilçeyi seçiniz.">
                                                <b-form-select
                                                id="ilgIlceId"
                                                name="ilgIlceId"
                                                v-model="Form.ilgIlceId"
                                                :options="ilceler"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="ilgIlceId-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="ilgIlceId-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="İlgilendiği Mahalle"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="ilgMahalleIdGroup" label="İlgilendiği Mahalle" label-for="ilgMahalleId" description="Başvuruyu yapan kişinin ilgilendiğin mahalle seçiniz.">
                                                <b-form-select
                                                id="ilgMahalleId"
                                                name="ilgMahalleId"
                                                v-model="Form.ilgMahalleId"
                                                :options="mahalleler"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="ilgMahalleId-live-feedback"
                                                ></b-form-select>
    
                                                <b-form-invalid-feedback id="ilgMahalleId-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Meslek"
                                        :rules="{ required: true, min:3}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="meslekGroup" label="Meslek" label-for="meslek" description="Başvuruyu yapan kişinin mesleğini yazınız.">
                                                <b-form-input
                                                id="meslek"
                                                name="meslek"
                                                v-model="Form.meslek"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="meslek-live-feedback"
                                                ></b-form-input>
    
                                                <b-form-invalid-feedback id="meslek-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Notlar"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="notlarGroup" label="Notlar" label-for="notlar" description="Başvuruyla alakalı bir notunuz var ise yazınız.">
                                                <b-form-textarea
                                                id="notlar"
                                                name="notlar"
                                                v-model="Form.notlar"
                                                rows="3"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="notlar-live-feedback"
                                                ></b-form-textarea>
    
                                                <b-form-invalid-feedback id="notlar-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row ">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Marka"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="marka" label="İstediği Marka" label-for="marka" description="Başvuruyu yapan kişinin ilgilendiği markayı seçiniz.">
                                                <b-form-select
                                                id="marka"
                                                name="marka"
                                                v-model="Form.encMarkaId"
                                                :options="markalar"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="marka-live-feedback"
                                                ></b-form-select>
    
                                                <b-form-invalid-feedback id="marka-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <b-button type="submit" variant="primary">Kaydet</b-button>
                                <b-button class="ml-2" variant="danger" @click="resetForm()">Formu Temizle</b-button>
                            </b-form>
                        </validation-observer>
                    </div>
                   
                </div>
            </div>
         </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            breadLinks:[
                {path:'/onbasvuru/listele', title:'Ön başvuru listesi', icon:'list'}
            ],
            Form : {
                adSoyad: "Burak",
                email: "burak.koroglu@mail.com",
                gsm: '0(535) 576 3143',
                sehirId: 1,
                ilgSehirId: 1,
                ilgIlceId: 1,
                ilgMahalleId: 1,
                meslek: 'Yazılımcı',
                nasilUlastiniz: 1,
                durum: 1,
                notlar: "Bu bir ön başvuru notudur",
                encAtananKullaniciId: null,
                encMarkaId: null
            },
            sehirler: [
                { value: null, text: "Seçiniz" },
            ],
            ilceler: [
                { value: null, text: "Seçiniz" },
            ],
            mahalleler: [
                { value: null, text: "Seçiniz" },
            ],
            markalar: [
                { value: null, text: "Seçiniz" }
            ]
        };
    },
    mounted(){
        this.getMarkaList();
        this.Form.encMarkaId = this.user.kullanici.markaId;
        this.getSehir();
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    watch: {
        'Form.ilgSehirId': function(newValue, oldValue) {
            this.getIlce(newValue);
        },
        'Form.ilgIlceId': function(newValue, oldValue) {
            this.getMahalle(newValue);
        }
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
        },
        resetForm(){
            this.markaAddForm = {
                adSoyad: null,
                email: null,
                gsm: null,
                sehirId: 0,
                ilgSehirId: 0,
                ilgIlceId: 0,
                ilgMahalleId: 0,
                meslek: null,
                nasilUlastiniz: 0,
                durum: 0,
                notlar: null,
                encAtananKullaniciId: null,
                encMarkaId: null
            };

            this.$nextTick(() => {
            this.$refs.observer.reset();
            });            
        },
        addOnBasvuru(){
            var self = this;
            this.$connection.postData('onbasvuru/ekle',self.Form,
                function(response){
                    console.log('update data:',response.data);
                    self.$alertHelper.basic_with_action("Başarılı","Ön Başvuru Eklendi",function(){
                        console.log('encId:',response.data.encId);
                        //self.$router.push({name:'onbasvuru-update',params:{ id:response.data.encId}})
                    },undefined
                    );
                },undefined
            );
        },
        getMarkaList(){
            var self = this;
            this.$connection.getData('marka/alt-markalar-kullanici',
                function(response){
                    self.markalar = response.data.map(function(obj) {
                        return {
                            value: obj.encId,   // Yeni isim özelliği
                            text: obj.adi      // Yeni yaş özelliği
                        };
                    });
                },
                function(error){
                console.log("error çalıştı");
                });
        },
        getSehir(){
            var self = this;
            this.$connection.getData('adres/sehir',
                function(response) {
                    self.sehirler.push.apply(self.sehirler,response.data.map((obj)=>{
                        return {
                            value: obj.id,
                            text: obj.adi
                        }
                    }));
                },
                undefined
            );
        },
        getIlce(sehirId){
            var self = this;
            this.$connection.getData(`adres/ilce/${sehirId}`,
                function(response) {
                    self.ilceler = response.data.map((obj)=>{
                        return {
                            value: obj.id,
                            text: obj.adi
                        }
                    });
                },
                undefined
            );
        },
        getMahalle(ilceId){
            var self = this;
            this.$connection.getData(`adres/mahalle/${ilceId}`,
                function(response) {
                    self.mahalleler = response.data.map((obj)=>{
                        return {
                            value: obj.id,
                            text: obj.adi
                        }
                    });
                },
                undefined
            );
        },
    }
}
</script>