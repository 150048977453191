import Swal from 'sweetalert2';

export default {
    basic:function(title){
        Swal.fire({
            title
        });
    },
    basic_with_title:function(title, text, icon='success'){
        Swal.fire({
            title,
            text,
            icon
        });
    },
    basic_with_action:function(title,text,successFunc,errorFunc,icon='success'){
        Swal.fire({
            title,
            text,
            confirmButtonColor: '#4466f2',
            icon
        }).then((result)=>{
            if(result){
                if (successFunc!==undefined)
                    successFunc();
            }else{
                if (errorFunc!==undefined)
                    errorFunc();
            }
        });
    },
    advanced_with_action:function(title,text,successFunc,errorFunc,icon='success',confirmButtonText='Tamam',cancelButtonText='İptal'){
        Swal.fire({
            title,
            text,
            icon,
            showCancelButton: true,
            confirmButtonText,
            confirmButtonColor: '#4466f2',
            cancelButtonText,
            cancelButtonColor: '#4466f2',
        }).then((result)=>{
            console.log('helper değişmiş');
            if(result.isConfirmed){
                if (successFunc!==undefined)
                    successFunc();
            }else{
                if (errorFunc!==undefined)
                    errorFunc();
            }
        });
    }
}