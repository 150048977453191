<template>
    <div>
        <Breadcrumbs main="ilgi Durum" title="İlgi Durumları" :links="breadLinks"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


              <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>İlgi Durumları</h5><span>Aşağıda giriş yapmış olduğunuz kullanıcının markasına ait ilgi durumları listelenmektedir.</span>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(getList)">
                                <div class="form-row ">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Marka"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="marka" label="Marka" label-for="marka" description="İlgi durumlarının listeleneceği markayı seçiniz.">
                                                <b-form-select
                                                id="marka"
                                                name="marka"
                                                v-model="markaId"
                                                :options="markalar"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="marka-live-feedback"
                                                ></b-form-select>
        
                                                <b-form-invalid-feedback id="marka-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <!-- <div class="col-md-4 mb-3">
                                        <b-button type="submit" class="m-t-20" variant="primary">Filtrele</b-button>
                                    </div> -->
                                </div>
                            </b-form>
                        </validation-observer>
                    </div>

                    <div class="card-body">
                      <c-table :tablefields="tablefields" :items="itemList">
                        <template #cell(actions)="row">
                          <b-btn-group>
                            <router-link :to="'/ilgi-durum/duzenle/' + row.item.encId">
                              <b-button size="sm" class="mr-1" variant="secondary">Düzenle</b-button>
                            </router-link>
                            <b-button size="sm"  variant="danger" @click="deleteIlgiDurum(row.item.encId)">
                              Sil
                            </b-button>
                          </b-btn-group>
                        </template>
                        <template #cell(aktif)="data">
                            <aktif-cell :isAktif="data.value"></aktif-cell>
                        </template>
                        <template #cell(kayitTarihi)="row">
                            <tarih-cell :kayitTarihi="row.item.kayitTarihi" :guncelTarih="row.item.guncelTarih" :rowKey="row.item.encId" ></tarih-cell>
                        </template>
                      </c-table>
                    </div>

                </div>
              </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            breadLinks:[
                { path:'/ilgi-durum/ekle', title:'İlgi durumu ekle', icon:'plus'}
            ],
            tablefields: [
                { key: 'actions', label: 'İŞLEM', sortable: true, },
                { key: 'tanim', label: 'TANIM', sortable: true, },
                { key: 'renk', label: 'RENK', sortable: true },
                { key: 'oran', label: 'ORAN', sortable: true, },
                { key: 'aktif', label: 'AKTİF', sortable: true, },
                { key: 'kayitTarihi', label: 'KAYIT TARİHİ', sortable: true},
            ],
            itemList : [],
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],
            markalar: [
                { value: null, text: "Seçiniz" }
            ],
            markaId:''
        }
    },
    
    computed: {
        ...mapState({
            user: state => state.auth.user,
        }),
        sortOptions() {
        // Create an options list from our fields
        return this.tablefields
            .filter(f => f.sortable)
            .map(f => {
            return { text: f.label, value: f.key }
            })
        }
    },
    mounted() {
        this.markaId = this.user.kullanici.markaId;
        this.getList();
        this.getMarkaList();
    },
    watch:{
        markaId: function (newValue,oldValue) {
            this.getList(newValue);
        }
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        },
        getList(){
        var self = this;
        this.$connection.getData('ilgidurum/get-list-by-marka?markaId='+self.markaId,
            function(response){
              self.itemList = response.data;
              self.totalRows = self.itemList.length;
            },
            function(error){
            console.log("error çalıştı");
            });
        },
        deleteIlgiDurum(encId){
            var self = this;
            self.$alertHelper.advanced_with_action('İLGİ DURUMUNU SİL','İlgi durumunu kalıcı olarak silmek istediğinize emin misiniz ? \n (Bu işlem yetkiniz yok ise gerçekleşmeyecektir.)',
                function(){
                self.$connection.deleteData('ilgidurum/sil?id='+encId,function(){
                    self.itemList = self.itemList.filter(item => item.encId !=encId);
                    self.$alertHelper.basic_with_title('İlgi Durum Sil','İlgi Durumu Silindi');
                },undefined);
                },undefined,'warning');
        },
        getMarkaList(){
            var self = this;
            this.$connection.getData('marka/alt-markalar-kullanici',
                function(response){
                    self.markalar = response.data.map(function(obj) {
                        return {
                            value: obj.encId,   // Yeni isim özelliği
                            text: obj.adi      // Yeni yaş özelliği
                        };
                    });
                },
                function(error){
                console.log("error çalıştı");
                });
        },
    },
}
</script>