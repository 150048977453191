import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import Breadcrumbs from './components/bread_crumbs'
import { store } from './store';
import Vue2Filters from 'vue2-filters'
import VueSweetalert2 from 'vue-sweetalert2';
import VueFormWizard from 'vue-form-wizard';
import VueTour from 'vue-tour'
import Notifications from 'vue-notification'
import { Vue2Dragula } from 'vue2-dragula'
import Toasted from 'vue-toasted';
import SmartTable from 'vuejs-smart-table'
import * as VueGoogleMaps from 'vue2-google-maps'
import { VueMasonryPlugin } from 'vue-masonry'
import VueFeather from 'vue-feather';
import VueApexCharts from 'vue-apexcharts';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import tr from "vee-validate/dist/locale/tr.json";
import * as rules from "vee-validate/dist/rules";
import VueTheMask from 'vue-the-mask';

/*Components Start*/
import aktifCell from './components/aktif_cell'
import tarihCell from './components/tarih_cell'

import cTable from './components/c_table'
/*Components End*/

import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;

// Import Theme scss
import './assets/scss/app.scss'


//
import connection from "./helpers/axiosHelper"
import alertHelper from './helpers/alertHelper'

const pluginHelpers = {
  install () {
    Vue.connection = connection;
    Vue.prototype.$connection = connection;
    Vue.alertHelper = alertHelper;
    Vue.prototype.$alertHelper = alertHelper;
  }
}


Icon.Default.mergeOptions({
  iconRetinaUrl: require("./assets/images/vue2leaflet/marker-icon.png"),
  iconUrl: require("./assets/images/vue2leaflet/marker-icon.png")
});

Vue.use(VueFeather);

Vue.use(Toasted,{
  iconPack: 'fontawesome'
});
Vue.use(Vue2Dragula);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'API_KEY',
    libraries: 'places',
  },
});

Vue.use(VueTheMask);

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("tr", tr);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.component("AktifCell",aktifCell);
Vue.component("TarihCell",tarihCell);

Vue.component("CTable",cTable);

Vue.use(Notifications)
Vue.use(Vue2Filters)
Vue.use(VueSweetalert2);
Vue.use(VueFormWizard)
Vue.use(VueTour)
Vue.use(BootstrapVue)
Vue.use(SmartTable)
Vue.use(require('vue-chartist'))
Vue.use(require('vue-moment'));
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.use(VueMasonryPlugin)
Vue.component('apexchart', VueApexCharts);
Vue.use(pluginHelpers);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')