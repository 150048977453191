import Vue from "vue"
import router from "../../router/index"
import axiosHelper from '../../helpers/axiosHelper'

const state = {
    
}

const getters = {
   
}

const mutations = {
   
}

const actions = {
    getMarkaList(context,payload){
        axiosHelper.getData('marka/alt-markalar-kullanici',
            function(response){
            context.commit('updateMarkaList',response.data)
            },
            function(error){
            console.log("error öalıştı");
            });
    },
    addMarka(context,payload){
        axiosHelper.postData('marka/ekle',payload.data,
            function(response){
                Vue.swal({
                    title:"Marka Eklendi",
                    text: response.data.encId
                })
            },
            function(error){
                console.log("error func eror:",error);
            }
        );
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}