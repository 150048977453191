<template>
    <div>
        <Breadcrumbs main="Marka" :title="marka.adi"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-4">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title mb-0">MARKA DETAY</h4>
                            <div class="card-options"><a class="card-options-collapse" href="#" data-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#" data-toggle="card-remove"><i class="fe fe-x"></i></a></div>
                        </div>
                        <div class="card-body">
                            <form>
                            <div class="row mb-2">
                                <div class="col-auto"><img class="img-70 rounded-circle" alt="" src="../../assets/images/user/7.jpg"></div>
                                <div class="col">
                                <h3 class="mb-1">{{ marka.adi }}</h3>
                                <p class="mb-4">{{marka.firmaAdi}}</p>
                                </div>
                            </div>
                            <div class="m-b-10">
                                <p class="f-w-600 f-16">Vergi Dairesi : <span class="f-14 f-w-400">{{ marka.vergiDairesi }}</span></p>
                            </div>
                            <div class="m-b-10">
                                <p class="f-w-600 f-16">Vergi Numarası : <span class="f-14 f-w-400">{{ marka.vergiNo }}</span></p>
                            </div>
                            <div class="m-b-10">
                                <p class="f-w-600 f-16">Bayi Sayısı : <span class="f-14 f-w-400">{{ marka.bayiAdedi }}</span></p>
                            </div>
                            <div class="m-b-10">
                                <p class="f-w-600 f-16">Fatura Tipi : <span class="f-14 f-w-400">{{ marka.faturaTipi ? "Kurumsal" : "Bireysel" }}</span></p>
                            </div>
                            <div class="m-b-10">
                                <p class="f-w-600 f-16">Aktif :<aktif-cell :value="marka.aktif" class="m-0 p-0"></aktif-cell></p>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <router-link :to="'/marka/update/' + marka.encId">
                                    <b-button size="xl" block class="mr-1" variant="secondary">Düzenle</b-button>
                                    </router-link>
                                </div>
                                <div class="col-md-6">
                                    <b-button size="xl" block  variant="danger" @click="deleteMarka(marka.encId)">
                                    Sil
                                    </b-button>
                                </div>
                            </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router';

export default {
    data() {
        return {
            id : '',
            marka : {}
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getDetay();
    },
    methods: {
        getDetay(){
            var self = this;
            this.$connection.getData('marka/get?id='+self.id,function(response){
                self.marka = response.data
            },undefined);
        },
        deleteMarka(encId){
            var self = this;
            self.$alertHelper.advanced_with_action('MARKA SİL','Markayı kalıcı olarak silmek istediğinize emin misiniz ? \n (Bu işlem yetkiniz yok ise gerçekleşmeyecektir.)',
                function(){
                self.$connection.getData('marka/sil?eId='+encId,function(){
                    self.$alertHelper.basic_with_title('Marka Sil','Marka Silindi');
                    router.push('/marka/listele')
                },undefined);
                },undefined,'warning');
        }
    },
}
</script>
