<template>
    <div>
        <Breadcrumbs main="Marka " title="Alt Marka Ekle"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">

                <div class="card">
                    <div class="card-header">
                        <h5>Alt Marka Ekleme</h5><span>Girdiğiniz bilgiler ile üst markanıza <span class="text-danger">bağlı olan</span> bir alt marka ekleyebilirsiniz.</span>
                    </div>
                    <div class="card-body">
                       
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(addMarka)">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Marka Adı"
                                        :rules="{ required: true, min: 3 }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="adi" label="Markanızın Adı" label-for="adi" description="Markanızın sistem içinde gözükmesini istediğiniz adı giriniz.">
                                                <b-form-input
                                                id="adi"
                                                name="adi"
                                                v-model="markaAddForm.adi"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="adi-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="adi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Firma Adı"
                                        :rules="{ required: true, min: 3 }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="firmaAdi" label="Firma Adı" label-for="firmaAdi" description="Markanın resmi firma adını giriniz.">
                                                <b-form-input
                                                id="firmaAdi"
                                                name="firmaAdi"
                                                v-model="markaAddForm.firmaAdi"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="firmaAdi-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="firmaAdi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Bayi Adedi" :rules="{ required: true, numeric:true }" v-slot="validationContext">
                                            <b-form-group id="bayiAdedi" label="Markanın Bayi Sayısı" label-for="bayiAdedi" >
                                                <b-form-input
                                                id="bayiAdedi"
                                                name="bayiAdedi"
                                                type="number"
                                                v-model="markaAddForm.bayiAdedi"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="bayiAdedi-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="bayiAdedi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Vergi Numarası"
                                        :rules="{ required: true, numeric:true, length:10  }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="vergiNo" label="Vergi Numarası" label-for="vergiNo" description="Markanızın 10 haneli vergi numarasını giriniz.">
                                                <b-form-input
                                                id="vergiNo"
                                                name="vergiNo"
                                                v-model="markaAddForm.vergiNo"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="vergiNo-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="vergiNo-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Vergi Dairesi"
                                        :rules="{ required: true, alpha_num:true }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="vergiDairesi" label="Vergi Dairesi" label-for="vergiDairesi" description="Markanın bağlı olduğu vergi dairesini giriniz.">
                                                <b-form-input
                                                id="vergiDairesi"
                                                name="vergiDairesi"
                                                v-model="markaAddForm.vergiDairesi"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="vergiDairesi-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="vergiDairesi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Fatura tipi" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="faturaTipi" label="Fatura Tipi" label-for="faturaTipi" description="Fatura tipini seçiniz.">
                                                <b-form-select
                                                id="faturaTipi"
                                                name="faturaTipi"
                                                :options="faturaTipleri"
                                                size="sm"
                                                v-model="markaAddForm.faturaTipi"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="faturaTipi-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="faturaTipi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Sıra"
                                        :rules="{ required: true, numeric:true }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="sira" label="Sıra" label-for="sira" description="Markaların içindeki gözükmesini istediğiniz sırayı belirtin(Sırası büyük olan başta gözükür).">
                                                <b-form-input
                                                id="sira"
                                                name="sira"
                                                v-model="markaAddForm.sira"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="sira-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="sira-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Aktif"
                                        :rules="{required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="aktifGroup" label-for="aktif" class="p-40" description="Markayı pasif olarak eklemek için kutudaki işareti kaldırabilirsiniz).">
                                                <b-form-checkbox
                                                id="aktif"
                                                name="aktif"
                                                v-model="markaAddForm.aktif"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="aktif-live-feedback"
                                                >Aktif</b-form-checkbox>
                                                <b-form-invalid-feedback id="aktif-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <h6 class="f-20 f-w-600">Özellikler</h6>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Primary Color"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="primarycolor" label="Primary Color" label-for="primarycolor" description="Markanızın birincil rengini(HEX) belirtiniz.">
                                                <b-form-input
                                                id="primarycolor"
                                                name="primarycolor"
                                                v-model="markaAddForm.ozellikler.primarycolor"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="primarycolor-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="primarycolor-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Secondary Color"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="secondarycolor" label="Secondary Color" label-for="secondarycolor" description="Markanızın ikincil rengini(HEX) belirtiniz.">
                                                <b-form-input
                                                id="secondarycolor"
                                                name="secondarycolor"
                                                v-model="markaAddForm.ozellikler.secondarycolor"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="secondarycolor-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="secondarycolor-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row ">

                                </div>
                                <b-button type="submit" variant="primary">Kaydet</b-button>
                                <b-button class="ml-2" variant="danger" @click="resetForm()">Formu Temizle</b-button>
                            </b-form>
                        </validation-observer>
                    </div>
                   
                </div>
            </div>
         </div>
        </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            markaAddForm : {
            adi: 'burak',
            firmaAdi: "null",
            encUstMarkaId: null,
            bayiAdedi: +12,
            vergiNo: "1234567890",
            vergiDairesi: "sadasd",
            faturaTipi: true,
            ozellikler: {
                primarycolor: null,
                secondarycolor: null,
            },
            sira: 12,
            tip: 1,
            ayarlar: "",
            aktif:true,
            },
            faturaTipleri: [
                { value: false, text: "Bireysel" },
                { value: true, text: "Kurumsal" }
            ],
        };
    },
    mounted(){
        this.markaAddForm.encUstMarkaId = this.user.kullanici.markaId;
        
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
        },
        resetForm(){
            this.markaAddForm = {
            adi: null,
            firmaAdi: null,
            encUstMarkaId: null,
            bayiAdedi: null,
            vergiNo: null,
            vergiDairesi: null,
            faturaTipi: null,
            ozellikler: {
                primarycolor: null,
                secondarycolor: null,
            },
            sira: null,
            tip: 1,
            ayarlar: null,
            aktif:true
            };

            this.$nextTick(() => {
            this.$refs.observer.reset();
            });            
        },
        addMarka(){
            var self = this;
            this.$connection.postData('marka/ekle',self.markaAddForm,
                function(response){
                    console.log('update data:',response.data);
                    self.$alertHelper.basic_with_action("Başarılı","Marka Eklendi",function(){
                        self.$router.push({name:'marka-update',params:{ id:response.data.encId}})
                    },undefined
                    );
                },undefined
            );
        }
    }
}
</script>
