<template>
    <div>
        <Breadcrumbs main="Ön Başvuru " title="Ön Başvuru Detay" />
        <ilgi_durum :markaId="basvuruToAdd.encMarkaId" :onBasvuruId="id" ></ilgi_durum>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <span>Ön başvurunun detayları aşağıda yer almaktadır.</span>
                    </div>
                    <div class="card-body">
                        <div class="row details-about">
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">AD SOYAD :</span>
                                    <p>{{ onBasvuru.adSoyad }}</p>
                                </div>
                            </div> 
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">EMAİL :</span>
                                    <p>{{ onBasvuru.email }}</p>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">GSM :</span>
                                    <p>{{ onBasvuru.gsm }}</p>
                                </div>
                            </div> 
                        </div>
                        <div class="row details-about">
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">BAŞVURDUĞU ŞEHİR :</span>
                                    <p>{{ onBasvuru.sehirAdi}}</p>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">İLGİLENDİĞİ ŞEHİR :</span>
                                    <p>{{ onBasvuru.ilgSehirAdi }}</p>
                                </div>
                            </div> 
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">İLGİLENDİĞİ İLÇE :</span>
                                    <p>{{ onBasvuru.ilgIlceAdi }}.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row details-about">
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">İLGİLENDİĞİ MAHALLE :</span>
                                    <p>{{ onBasvuru.ilgMahalleAdi }}</p>
                                </div>
                            </div> 
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">MESLEK :</span>
                                    <p>{{ onBasvuru.MESLEK }}</p>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="your-details your-details-xs"><span class="f-w-600">NOTLAR :</span>
                                    <p>{{ onBasvuru.notlar }}</p>
                                </div>
                            </div> 
                        </div>
                        <div class="row ">
                            <div class="col-md-12 m-t-10 text-center">
                              <b-button v-if="!basvuruyaCevir" size="lg" class="mr-1" variant="success" @click="basvuruEkle()">BAŞVURUYA ÇEVİR</b-button>
                              <b-button v-else size="lg" class="mr-1" variant="danger" @click="basvuruEkle()">VAZGEÇ</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="basvuruyaCevir">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <span>Gerekli bilgileri girerek bu ön başvuru kaydını bir başvuru sürecine çevirebilirsiniz.</span>
                    </div>
                    <div class="card-body">
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(basvuruKaydet)">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="ADRES"
                                        :rules="{ required: true, min: 3 }"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="adresGroup" label="ADRES" label-for="adres" description="Adres bilgisi giriniz.">
                                                <b-form-input
                                                id="adres"
                                                name="adres"
                                                v-model="basvuruToAdd.adres"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="adres-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="adres-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="TELEFON"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="telGroup" label="Telefon Numarası" label-for="tel" description="İkinci bir telefon numarası girebilirsiniz.">
                                                <b-form-input
                                                id="tel"
                                                name="tel"
                                                v-model="basvuruToAdd.tel"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="tel-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="tel-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Bayi Adı" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="bayiAdiGroup" label="Bayi Adı" label-for="bayiAdi" >
                                                <b-form-input
                                                id="bayiAdi"
                                                name="bayiAdi"
                                                type="text"
                                                v-model="basvuruToAdd.bayiAdi"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="bayiAdi-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="bayiAdi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Durum"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="durumGroup" label="Durum" label-for="durum" description="Başvuru durumunu seçiniz.">
                                                <b-form-select
                                                id="durum"
                                                name="durum"
                                                v-model="basvuruToAdd.durum"
                                                :options="durumlar"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="durum-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="durum-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Basşvuru Tipi"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="basvuruTipiGroup" label="Başvuru Tipi" label-for="basvuruTipi" description="Başvuru tipini seçiniz.">
                                                <b-form-select
                                                id="basvuruTipi"
                                                name="basvuruTipi"
                                                v-model="basvuruToAdd.basvuruTipi"
                                                :options="tipler"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="basvuruTipi-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="basvuruTipi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Notlar"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="notlarGroup" label="Notlar" label-for="notlar" description="Başvuruyla alakalı bir notunuz var ise yazınız.">
                                                <b-form-textarea
                                                id="notlar"
                                                name="notlar"
                                                v-model="basvuruToAdd.notlar"
                                                rows="3"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="notlar-live-feedback"
                                                ></b-form-textarea>
    
                                                <b-form-invalid-feedback id="notlar-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Firma Ünvanı"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="firmaUnvanıGroup" label="Firma Ünvanı" label-for="firmaUnvanı" description="Firma ünvanını giriniz.">
                                                <b-form-input
                                                id="firmaUnvanı"
                                                name="firmaUnvanı"
                                                v-model="basvuruToAdd.firmaUnvanı"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="firmaUnvanı-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="firmaUnvanı-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Vade Sayısı"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="vadeSayisiGroup" label="Vade Sayısı" label-for="vadeSayisi" description="Vade sayısı seçiniz.">
                                                <b-form-select
                                                id="vadeSayisi"
                                                name="vadeSayisi"
                                                v-model="basvuruToAdd.vadeSayisi"
                                                :options="vadeSayilari"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="vadeSayisi-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="vadeSayisi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>                                    
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Metrekare" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="metrekareGroup" label="Metre Kare" label-for="metrekare" >
                                                <b-form-input
                                                id="metrekare"
                                                name="metrekare"
                                                type="number"
                                                v-model="basvuruToAdd.metrekare"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="metrekare-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="metrekare-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Kapora Bedeli" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="kaporaBedeliGroup" label="Kapora Bedeli" label-for="kaporaBedeli" >
                                                <b-form-input
                                                id="kaporaBedeli"
                                                name="kaporaBedeli"
                                                type="number"
                                                v-model="basvuruToAdd.kaporaBedeli"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="kaporaBedeli-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="kaporaBedeli-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Franchise Bedeli" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="franchiseBedeliGroup" label="Franchise Bedeli" label-for="franchiseBedeli" >
                                                <b-form-input
                                                id="franchiseBedeli"
                                                name="franchiseBedeli"
                                                type="number"
                                                v-model="basvuruToAdd.franchiseBedeli"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="franchiseBedeli-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="franchiseBedeli-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Vergi Dairesi" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="vergiDairesiGroup" label="Vergi Dairesi" label-for="vergiDairesi" >
                                                <b-form-input
                                                id="vergiDairesi"
                                                name="vergiDairesi"
                                                type="text"
                                                v-model="basvuruToAdd.vergiDairesi"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="vergiDairesi-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="vergiDairesi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider name="Vergi No" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group id="vergiNoGroup" label="Vergi Numarası" label-for="vergiNo" >
                                                <b-form-input
                                                id="vergiNo"
                                                name="vergiNo"
                                                type="number"
                                                v-model="basvuruToAdd.vergiNo"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="vergiNo-live-feedback"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="vergiNo-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Ödeme Türü"
                                        :rules="{ required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="odemeTuruIdGroup" label="Ödeme Türü" label-for="odemeTuruId" description="Ödeme türünü seçiniz.">
                                                <b-form-select
                                                id="odemeTuruId"
                                                name="odemeTuruId"
                                                v-model="basvuruToAdd.odemeTuruId"
                                                :options="odemeTurleri"
                                                size="sm"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="odemeTuruId-live-feedback"
                                                ></b-form-select>

                                                <b-form-invalid-feedback id="odemeTuruId-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Kapora ödendi mi"
                                        :rules="{required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="isKaporaOdendiGroup" label-for="isKaporaOdendi" class="p-40" description="Kapora ödendi mi ?">
                                                <b-form-checkbox
                                                id="isKaporaOdendi"
                                                name="isKaporaOdendi"
                                                v-model="basvuruToAdd.isKaporaOdendi"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="isKaporaOdendi-live-feedback"
                                                >Evet</b-form-checkbox>
                                                <b-form-invalid-feedback id="isKaporaOdendi-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <validation-provider
                                        name="Lokasyon belli mi"
                                        :rules="{required: true}"
                                        v-slot="validationContext"
                                        >
                                            <b-form-group id="isLokasyonBelliGroup" label-for="isLokasyonBelli" class="p-40" description="Bayinin lokasyonu belli mi ?">
                                                <b-form-checkbox
                                                id="isLokasyonBelli"
                                                name="isLokasyonBelli"
                                                v-model="basvuruToAdd.isLokasyonBelli"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="isLokasyonBelli-live-feedback"
                                                >Evet</b-form-checkbox>
                                                <b-form-invalid-feedback id="isLokasyonBelli-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                                <b-button type="submit" variant="primary">Kaydet</b-button>
                                <b-button class="ml-2" variant="danger" @click="resetForm()">Formu Temizle</b-button>
                            </b-form>
                        </validation-observer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import ilgi_durum from '../../components/ilgi_durum.vue';
export default {
    data() {
        return {
            id : '',
            onBasvuru : {

            },
            basvuruyaCevir:false,
            basvuruToAdd: {
                encOnBasvuruId: "",
                atananKullaniciId: 0,
                encAtananKullaniciId: "",
                adres: "",
                tel: "",
                durum: 0,
                basvuruTipi: 0,
                bayiAdi: "",
                notlar: "",
                firmaUnvan: "",
                isLokasyonBelli: false,
                isTSEUyumlu: false,
                metreKare: 0,
                kaporaBedeli: 0,
                franchiseBedeli: 0,
                vergiDairesi: "",
                vergiNo: "",
                isKaporaOdendi: false,
                odemeTuruId: 0,
                vadeSayisi: 0,
                odenenVadeSayisi: 0,
                karMarji: 0,
                cariKodu: "",
                nakitKasaKodu: "",
                firmaNo: "",
                tsehyb: "",
                encMarkaId: ""
            },
            vadeSayilari: [
                { value: null, text: "Seçiniz" },
            ],
            odemeTurleri: [
                { value: null, text: "Seçiniz" },
            ],
            tipler: [
                { value: null, text: "Seçiniz" },
            ],
            durumlar: [
                { value: null, text: "Seçiniz" },
            ],
        }
    },
    components:{
        ilgi_durum
    },
    created() {
        this.id = this.$route.params.id;
        this.getDetay();
        this.basvuruToAdd.encOnBasvuruId = this.$route.params.id;
        this.basvuruToAdd.encMarkaId = this.user.kullanici.markaId;
    },
    mounted() {
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
        },
        resetForm(){
            this.basvuruToAdd = {
                encOnBasvuruId: this.$route.params.id,
                atananKullaniciId: 0,
                encAtananKullaniciId: "",
                adres: "",
                tel: "",
                durum: 0,
                basvuruTipi: 0,
                bayiAdi: "",
                notlar: "",
                firmaUnvan: "",
                isLokasyonBelli: false,
                isTSEUyumlu: false,
                metreKare: 0,
                kaporaBedeli: 0,
                franchiseBedeli: 0,
                vergiDairesi: "",
                vergiNo: "",
                isKaporaOdendi: false,
                odemeTuruId: 0,
                vadeSayisi: 0,
                odenenVadeSayisi: 0,
                karMarji: 0,
                cariKodu: "",
                nakitKasaKodu: "",
                firmaNo: "",
                tsehyb: "",
                encMarkaId: this.user.kullanici.markaId
            };

            this.$nextTick(() => {
            this.$refs.observer.reset();
            });            
        },
        getDetay(){
            var self = this;
            this.$connection.getData('onbasvuru/get?id='+self.id,function(response){
                console.log(response.data);
                self.onBasvuru = response.data
            },undefined);
        },   
        basvuruEkle(){
            this.basvuruyaCevir = !this.basvuruyaCevir;
        },
        basvuruKaydet(){
            console.log('basvuru kaydet girdi');
            var self = this;
            this.$connection.postData('basvuru/basvuruya-cevir',self.basvuruToAdd,
                function(response){
                    self.$alertHelper.basic_with_action("Başarılı","Başvuru Eklendi",function(){
                        console.log('encId:',response.data.encId);
                        //self.$router.push({name:'onbasvuru-update',params:{ id:response.data.encId}})
                    },undefined
                    );
                },undefined
            );
        } 
    },
}
</script>
<style>
    #danger-ilgi{
        opacity: 0.5;
    }
</style>