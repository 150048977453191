<template>
    <div>
        <Breadcrumbs main="Başvuru" title="Başvuru Listele" :links="breadLinks"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


              <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                      <h5>Başvuru kayıtları</h5><span>Aşağıda giriş yapmış olduğunuz kullanıcının başvuru kayıtlarının listesi yer almaktadır.</span>
                    </div>

                    <div class="card-body">
                      <c-table :tablefields="tablefields" :items="itemList">
                        <template #cell(actions)="row">
                          <b-btn-group>
                            <router-link :to="'/basvuru/detail/' + row.item.encId">
                              <b-button size="sm" class="mr-1" variant="primary">Detay</b-button>
                            </router-link>
                            <router-link :to="'/basvuru/duzelt/' + row.item.encId">
                              <b-button size="sm" class="mr-1" variant="secondary">Düzenle</b-button>
                            </router-link>
                            <b-button size="sm"  variant="danger" @click="deleteBasvuru(row.item.encId)">
                              Sil
                            </b-button>
                          </b-btn-group>
                        </template>
                        <template #cell(kayitTarihi)="row">
                            <tarih-cell :kayitTarihi="row.item.kayitTarihi" :guncelTarih="row.item.guncelTarih" :rowKey="row.item.encId" ></tarih-cell>
                        </template>
                      </c-table>
                    </div>

                </div>
              </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            breadLinks:[
                { path:'/basvuru/ekle', title:'Başvuru ekle', icon:'plus'}
            ],
            tablefields: [
                { key: 'actions', label: 'İŞLEMLER', sortable: true, },
                { key: 'ilgSehirAdi', label: 'İLG. ŞEHİR', sortable: true },
                { key: 'adres', label: 'ADRES', sortable: true, },
                { key: 'email', label: 'EMAİL', sortable: true, },
                { key: 'gsm', label: 'GSM', sortable: true, },
                { key: 'basvuruTipiStre', label: 'BAŞVURU TİPİ', sortable: true, },
                { key: 'durumStr', label: 'DURUM', sortable: true},
                { key: 'kayitTarihi', label: 'KAYIT TARİHİ', sortable: true},
            ],
            itemList : [],
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],
        }
    },
    
    computed: {
        ...mapState({
            user: state => state.auth.user,
        }),
        sortOptions() {
        // Create an options list from our fields
        return this.tablefields
            .filter(f => f.sortable)
            .map(f => {
            return { text: f.label, value: f.key }
            })
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        },
        getList(){
        var self = this;
        this.$connection.getData('basvuru/get-liste-by-marka?markaId='+self.user.kullanici.markaId,
            function(response){
              self.itemList = response.data;
              self.totalRows = self.itemList.length;
            },
            function(error){
            console.log("error çalıştı");
            });
        },
        deleteOnBasvuru(encId){
            var self = this;
            self.$alertHelper.advanced_with_action('BAŞVURU SİL','Başvuruyu kalıcı olarak silmek istediğinize emin misiniz ? \n (Bu işlem yetkiniz yok ise gerçekleşmeyecektir.)',
                function(){
                self.$connection.deleteData('basvuru/sil?id='+encId,function(){
                    self.itemList = self.itemList.filter(item => item.encId !=encId);
                    self.$alertHelper.basic_with_title('Başvuru Sil','Başvuru Silindi');
                },undefined);
                },undefined,'warning');
        }
    },
}
</script>