import axios from "axios";
import {store} from "../store"
import Vue from "vue";
import { Store } from "vuex";

axios.defaults.baseURL = 'https://franchiseuiapi.apisoftyazilim.com/api/';

axios.interceptors.request.use((config) => {
    console.log('Request Interceptor:', config);
    // İstek konfigürasyonunu değiştirme veya üzerine ek işlemler burada yapılabilir
    return config;
  }, (error) => {
    Vue.swal({
        title:"Hata",
        text: `Şu anda bu işlemi gerçekleştiremiyoruz.`
    })
    console.error('Request Error Interceptor:', error);

    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => {
    console.log('Response Interceptor:', response);
    // Yanıt verisini değiştirme veya üzerine ek işlemler burada yapılabilir
    return response;
  }, (error) => {
    Vue.swal({
        title:"Hata",
        text: `Şu anda bu işlemi gerçekleştiremiyoruz.`
    })
    console.error('Response Error Interceptor:', error);
    return Promise.reject(error);
  });

export default{
    getToken: function () {
        return store.getters['auth/getUser'].token;
    },
    setAuthorization: function (headers) {
        var token = this.getToken();
        if (token !== null)
            headers["Authorization"] = "Bearer " + token;
    },
    postData : function (url, data, successFunc, errorFunc){
        store.dispatch('general/setLoaderStatusAction',true);
        

        let headers = {};
        this.setAuthorization(headers);

        let config = { headers }

        axios.post(url,data,config)
            .then((response)=>{
                    store.dispatch('general/setLoaderStatusAction',false);
                    if(successFunc != undefined){
                        successFunc(response);
                    }
                    else{
                        Vue.swal({
                            title:"Başarılı",
                            text:"İşlem tamamlandı!"
                        });
                    }
                })
                .catch(error =>{
                    store.dispatch('general/setLoaderStatusAction',false);
                    if(error.response){
                        console.log(error.response.status);
                        switch(error.response.status) {
                            case 403:
                                Vue.swal({
                                    title:"Hata",
                                    text: "Bu işlemi yapmaya yetkiniz yok"
                                })
                                return;
                            case 400:
                                Vue.swal({
                                    title:"Hata",
                                    text: error.response.data.Message
                                })
                                return;
                            default:
                                break;
                        }
                    }
                    this.validErrorHandler(error.response.data, errorFunc);
                });
    },
    getData : function (url, successFunc, errorFunc){
        store.dispatch('general/setLoaderStatusAction',true);

        let headers = {};
        this.setAuthorization(headers);

        let config = { headers }

        axios.get(url,config)
            .then((response)=>{
                store.dispatch('general/setLoaderStatusAction',false);
                if(successFunc != undefined){
                    
                    successFunc(response);
                }
                else{
                    Vue.swal({
                        title:"Başarılı",
                        text:"İşlem tamamlandı!"
                    });
                }
            })
            .catch(error =>{
                store.dispatch('general/setLoaderStatusAction',false);
                if(error.response){
                    console.log(error.response.status);
                    switch(error.response.status) {
                        case 403:
                            Vue.swal({
                                title:"Hata",
                                text: "Bu işlemi yapmaya yetkiniz yok"
                            })
                            return;
                        case 400:
                            console.log('axios error func',errorFunc);
                            if(errorFunc === undefined){
                                Vue.swal({
                                    title:"Hata",
                                    text: error.response.data.Message
                                })
                            }
                          return;
                        default:
                          break;
                      } 
                }
                this.validErrorHandler(error.response.data, errorFunc);
            });
    },
    deleteData : function (url, successFunc, errorFunc){
        store.dispatch('general/setLoaderStatusAction',true);

        let headers = {};
        this.setAuthorization(headers);

        let config = { headers }

        axios.delete(url,config)
            .then((response)=>{
                store.dispatch('general/setLoaderStatusAction',false);
                if(successFunc != undefined){
                    
                    successFunc(response);
                }
                else{
                    Vue.swal({
                        title:"Başarılı",
                        text:"İşlem tamamlandı!"
                    });
                }
            })
            .catch(error =>{
                store.dispatch('general/setLoaderStatusAction',false);
                if(error.response){
                    console.log(error.response.status);
                    switch(error.response.status) {
                        case 403:
                            Vue.swal({
                                title:"Hata",
                                text: "Bu işlemi yapmaya yetkiniz yok"
                            })
                            return;
                        case 400:
                            console.log('axios error func',errorFunc);
                            if(errorFunc === undefined){
                                Vue.swal({
                                    title:"Hata",
                                    text: error.response.data.Message
                                })
                            }
                          return;
                        default:
                          break;
                      } 
                }
                this.validErrorHandler(error.response.data, errorFunc);
            });
    },
    validErrorHandler : function (res, errorFunc = undefined) {
        if (res.errors !== undefined) {
            var errorText = "";
            var keys = Object.keys(res.errors);
            for (let i = 0; i < keys.length; i++) {
                var key = keys[i];
                // Here you can use a string value as the property name
                for (var k = 0; k < res.errors[key].length; k++) {
                    errorText += res.errors[key][k] + "\n";
                }
                if (errorText !== "") {
                    Vue.swal({
                        title:"Form Hatası",
                        text: errorText
                    })
                }
            }
        } else {
            if (errorFunc !== undefined) {
                errorFunc(res);
            } else {
                Vue.swal({
                    title:"İşlem Hatası",
                    text: res.StatusCode
                })
            }
        }
    }

}