import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body'

/* Dashboards */
import dashboard from '../pages/dashboard/default'

/*Auth*/
import loginPage from '../auth/loginPage'
import auth from '../auth/auth'

/*Marka*/
import markaAdd from '../pages/marka/marka_add'
import markaUpdate from '../pages/marka/marka_update'
import markaListele from '../pages/marka/marka_listele'
import markaDetail from '../pages/marka/marka_detail'
import {store} from "../store"

/*Ön Başvuru*/
import onBasvuruListe from '../pages/on_basvuru/on_basvuru_liste'
import onBasvuruAdd from '../pages/on_basvuru/on_basvuru_add'
import onBasvuruDetail from '../pages/on_basvuru/on_basvuru_detail'
/*Başvuru */
import basvuruListe from '../pages/basvuru/basvuru_liste'
/*İlgi Durumlari */
import ilgiDurumEkle from '../pages/ilgi_durumlari/ilgi_durum_ekle'
import ilgiDurumDuzenle from '../pages/ilgi_durumlari/ilgi_durum_duzenle'
import ilgiDurumListe from '../pages/ilgi_durumlari/ilgi_durum_liste'
/*Aşama */
import asamaEkle from '../pages/asama/asama_add'
import asamaDuzenle from '../pages/asama/asama_duzenle'
import asamaListele from '../pages/asama/asama_listele'

Vue.use(Router)

const routes = [
{ path: '', redirect: { name: 'dashboard' }},
{
  path: '',
  component: Body,
  meta: {
    requiresAuth: true
  },
  children: [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: dashboard,
    meta: {
      title: 'Franchise Manager',
    }
  }
  ]
  },
  {
    path:'/auth',
    component:auth,
    children:[
      {
        path:'login',
        component:loginPage,
        meta: {
          requiresAuth: false,
        }
      }
    ]
  },
  {
    path: '/marka',
    component: Body,
    children: [
      {
        path: 'listele',
        name: 'marka-listele',
        component: markaListele,
        meta: {
            title: 'Kullanıcı Markaları',
        }
      },
      {
        path: 'add',
        name: 'marka-add',
        component: markaAdd,
        meta: {
            title: 'Alt Marka Ekle',
        }
      },
      {
        path: 'update/:id',
        name: 'marka-update',
        component: markaUpdate,
        meta: {
            title: 'Marka Düzenle',
        }
      },
      {
        path: 'detail/:id',
        name: 'marka-detail',
        component: markaDetail,
        meta: {
            title: 'Detay',
        }
      }
    ],
  },
  {
    path:'/onbasvuru',
    component: Body,
    children: [
      {
        path: 'listele',
        name: 'on-basvuru-listele',
        component: onBasvuruListe,
        meta: {
            title:'Ön başvuru listesi'
        }
      },
      {
        path: 'ekle',
        name: 'on-basvuru-ekle',
        component: onBasvuruAdd,
        meta: {
            title:'Ön başvuru ekle'
        }
      },
      {
        path: 'detay/:id',
        name: 'on-basvuru-detay',
        component: onBasvuruDetail,
        meta: {
            title:'Ön başvuru detay'
        }
      } 
    ]
  },
  {
    path:'/basvuru',
    component: Body,
    children: [
      {
        path: 'listele',
        name: 'basvuru-listele',
        component: basvuruListe,
        meta: {
            title:'Başvuru listesi'
        }
      } 
    ]
  },
  {
    path:'/ilgi-durum',
    component: Body,
    children: [
      {
        path: 'ekle',
        name: 'ilgi-durum-ekle',
        component: ilgiDurumEkle,
        meta: {
            title:'İlgi durum ekle'
        }
      },
      {
        path: 'duzenle/:id',
        name: 'ilgi-durum-duzenle',
        component: ilgiDurumDuzenle,
        meta: {
            title:'İlgi durum düzenle'
        }
      }, 
      {
        path: 'listele',
        name: 'ilgi-durum-liste',
        component: ilgiDurumListe,
        meta: {
            title:'İlgi durum listele'
        }
      } 
    ]
  },
  {
    path:'/asama',
    component: Body,
    children: [
      {
        path: 'ekle',
        name: 'asama-ekle',
        component: asamaEkle,
        meta: {
            title:'Aşama Ekle'
        }
      },
      {
        path: 'duzenle/:id',
        name: 'asama-duzenle',
        component: asamaDuzenle,
        meta: {
            title:'Aşama düzenle'
        }
      }, 
      {
        path: 'listele',
        name: 'asama-liste',
        component: asamaListele,
        meta: {
            title:'Aşama listele'
        }
      } 
    ]
  }
];

const router = new Router({
  routes,
  //base: '/endless/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const  path = ['/auth/login','/auth/register'];
  
    var isAuthenticated = store.getters['auth/isAuthenticated'];
   
    var isToAuth = path.includes(to.path); 
    
    if (!isAuthenticated && !isToAuth){
        next('auth/login')
    }
    return next()
  
})

export default router
